<template>
  <section>
    <nav-menu></nav-menu>
    <div class="page-content-lightestblue" data-rewardsshop-action="cart-empty">
      <div class="page-content-lightestblue__container">
        <div class="page-content-lightestblue__container-colLeft">
          <h2>{{ t("LBL_ORDER_FAIL_TITLE") }}</h2>
          <div>
            <p v-html="t('LBL_ORDER_FAIL_TEXT_NEW')"></p>
            <p class="text-red-dark font-medium" v-if="orderNumber">Bestellnummer: {{ orderNumber }}</p>
          </div>
        </div>
        <div class="page-content-lightestblue__container-colRight">
          <app-image :alt="order-fail" :src="require('../assets/img/empty-result-picture-data.png')"></app-image>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Button from "@/components/Button/Button";
  import NavMenu from "@/components/Menu/NavMenu";
  import AppImage from "@/components/Image/Image";
  import { useRouter } from "vue-router";
  import { ref, computed } from "vue";
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";

  export default {
    name: "OrderFail",
    components: {
      NavMenu,
      AppImage,
      Button,
    },
    setup() {
      const { t } = useI18n();
      const router = useRouter();
      const store = useStore();
      const orderNumber = ref("");

      const goToLandingPage = async () => {
        store.dispatch("catalog/setFilters", {});
        store.dispatch("catalog/emptyProductsList");
        router.push("/");
      };

      const cartData =  computed(()=> {
        return store.getters["cart/cart"];
      })
      orderNumber.value = cartData.value?.cart_id;

      return {
        goToLandingPage,
        orderNumber,
        t,
      };
    },
  };
</script>
<style scoped lang="postcss">
  .page-content-lightestblue {
    @apply bg-blue-lightest text-black-light pb-20;
    &__container {
      @apply w-11/12 mx-auto flex p-20 justify-center items-center text-black;

      max-width: 960px;
      h2,
      p {
        @apply mt-5;
        @media screen and (max-width: 767px) {
          @apply mx-2;
        }
      }

      &-colLeft {
        @apply col-span-2;

        @media (max-width: 600px) {
          @apply col-span-1;
        }
      }
      &-colRight {
        @apply col-span-1 relative;
        height: 100%;
      }

      @media (max-width: 800px) {
        @apply flex-col-reverse;
      }
    }
  }
  @media (min-width: 992px) {
    &__container {
      width: 960px;
    }
  }
</style>
